import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  videoWrapper: {
    paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    width: '100%',
    position: 'relative',
  },
  videoWrapperWide: {
    paddingTop: '52.75%' /* 4096:2160 Aspect Ratio (divide 2160 by 4096 = 0.527343) */,
    width: '100%',
    position: 'relative',
  },
  embed: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: '0 auto',
    width: '100%',
    height: '100%',
  },
}));

const previewImage = ({videoId, alt, videoUrl}) => {
  const jpegImgBase = `https://i.ytimg.com/vi/${videoId}`;
  const webpImgBase = `https://i.ytimg.com/vi_webp/${videoId}`;
  return `
    <style>
      * {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }

      html, body {
        height: 100%;
      }

      picture, source, img, span {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      span {
        height: 1.5em;
        text-align: center;
        font: 48px/1.5 sans-serif;
        color: white;
        text-shadow: 0 0 0.5em black;
      }
    </style>
    <a href='${videoUrl}' style="width: 100%">
      <picture>
         <source media="(max-width: 120px)" srcset="${webpImgBase}/default.webp" type="image/webp">
         <source media="(max-width: 120px)" srcset="${jpegImgBase}/default.jpg" type="image/jpeg">
         <source media="(max-width: 320px)" srcset="${webpImgBase}/mqdefault.webp" type="image/webp">
         <source media="(max-width: 320px)" srcset="${jpegImgBase}/mqdefault.jpg" type="image/jpeg">
         <source media="(max-width: 480px)" srcset="${webpImgBase}/hqdefault.webp" type="image/webp">
         <source media="(max-width: 480px)" srcset="${jpegImgBase}/hqdefault.jpg" type="image/jpeg">
         <source media="(max-width: 640px)" srcset="${webpImgBase}/sddefault.webp" type="image/webp">
         <source media="(max-width: 640px)" srcset="${jpegImgBase}/sddefault.jpg" type="image/jpeg">
         <source media="(min-width: 640px)" srcset="${webpImgBase}/maxresdefault.webp" type="image/webp">
         <source media="(min-width: 640px)" srcset="${jpegImgBase}/maxresdefault.jpg" type="image/jpeg">
         <img src="${jpegImgBase}/hqdefault.jpg" alt='Play YouTube video: ${alt}'>
      </picture>
      <span>▶&#xFE0E;</span>
    </a>
  `;
};

const Video = ({alt, videoId, preview, wide, platform = 'youtube'}) => {
  let src;
  if (platform === 'vimeo') {
    src = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`;
  } else {
    src = `https://www.youtube.com/embed/${videoId}?rel=0&autohide=1&showinfo=0&modestbranding=1`;
    if (preview) {
      src += '&autoplay=1';
    }
  }
  
  let srcDoc = null;
  if (preview && platform === 'youtube') {
    srcDoc = previewImage({videoUrl: src, videoId, alt});
  }
  
  const classes = useStyles();

  return (
    <Box className={wide ? classes.videoWrapperWide : classes.videoWrapper}>
      <iframe
        className={classes.embed}
        src={src}
        srcDoc={srcDoc}
        title={alt}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </Box>
  );
};

Video.propTypes = {
  videoId: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  wide: PropTypes.bool,
  platform: PropTypes.oneOf(['youtube', 'vimeo'])
};

Video.defaultProps = {
  preview: true,
  wide: false,
  platform: 'youtube'
};

export default Video;
